import { render, staticRenderFns } from "./TransactionForm.vue?vue&type=template&id=5b63ef14&"
import script from "./TransactionForm.vue?vue&type=script&lang=js&"
export * from "./TransactionForm.vue?vue&type=script&lang=js&"
import style0 from "./TransactionForm.vue?vue&type=style&index=0&id=5b63ef14&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_942d38e42bf4b987e783635c88cde9fa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="transactions-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :sm="24" :md="12">
              <h2 class="form-title">
                <a-icon :type="isView ? 'eye' : 'form'" class="form-icon" />
                <span>{{
                  isView
                    ? `${labels.form.titleView} número: ${form.code || ""}`
                    : labels.form.titleProcess
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :sm="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col>
                  <a-button-group>
                    <a-button
                      :type="isView ? 'primary' : 'danger'"
                      ghost
                      @click="handleCancel"
                      icon="arrow-left"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <a-button
                      v-if="
                        isAuthorizedToCancel &&
                        isView &&
                        !['CANCELLED', 'PENDING', 'REJECTED'].includes(
                          form.status
                        )
                      "
                      type="danger"
                      @click="confirmCancel"
                    >
                      <span>{{ labels.form.cancelTransaction }}</span>
                    </a-button>
                    <a-button
                      v-if="!isView && canConfirm"
                      type="primary"
                      :loading="submitting"
                      ghost
                      @click="validate().then(() => handleSubmit(false, true))"
                    >
                      <span>{{ labels.form.saveAndConfirm }}</span>
                    </a-button>
                    <a-dropdown-button
                      v-if="!isView"
                      @click="validate().then(handleSubmit)"
                      type="primary"
                      :loading="submitting"
                      :trigger="['click']"
                    >
                      {{ labels.form.save }}
                      <a-menu slot="overlay">
                        <a-menu-item
                          key="saveAndClose"
                          @click="
                            validate().then(() => handleSubmit(true, false))
                          "
                        >
                          <a-icon type="save" />{{ labels.form.saveAndClose }}
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown-button>
                    <a-button
                      v-if="
                        isView && ['PENDING'].includes(form.status) && canReject
                      "
                      type="danger"
                      @click="confirmReject"
                    >
                      <span>{{ labels.form.rejectTransaction }}</span>
                    </a-button>
                    <a-button
                      v-if="
                        isView &&
                        ['PENDING'].includes(form.status) &&
                        canApprove
                      "
                      type="primary"
                      @click="
                        isTransfer
                          ? handleApproveTransaction()
                          : confirmApprove()
                      "
                    >
                      <span>{{ labels.form.approveTransaction }}</span>
                    </a-button>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="start" :gutter="24">
                  <a-col :xs="24" :sm="24" :md="12">
                    <a-row type="flex" justify="space-between" :gutter="24">
                      <a-col :xs="24" :sm="24" :md="6">
                        <DatePicker
                          v-model="form.date"
                          :vid="labels.form.date.label"
                          :name="labels.form.date.label"
                          :formitem="{ label: labels.form.date.label }"
                          :placeholder="labels.form.date.placeholder"
                          :format="dateFormat"
                          rules="required"
                          :inputReadOnly="isView"
                          :disabled="isView"
                        />
                      </a-col>
                      <a-col
                        v-if="form.status"
                        :style="{ display: 'flex', alignItems: 'center' }"
                      >
                        <span>
                          <a-tag
                            :color="statusColor(form.status?.toLowerCase())"
                            :style="{
                              fontSize: '18px',
                              fontWeight: 600,
                              padding: '5px',
                            }"
                          >
                            {{ statusText(form.status?.toLowerCase()) }}
                          </a-tag>
                          <a-tooltip
                            v-if="
                              form.reject_reason && form.status === 'REJECTED'
                            "
                            :title="form.reject_reason"
                            placement="top"
                            trigger="click"
                          >
                            <a-icon
                              type="info-circle"
                              theme="twoTone"
                              twoToneColor="#eb2f96"
                              style="font-size: 18px"
                            />
                          </a-tooltip>
                        </span>
                      </a-col>
                    </a-row>
                    <a-row type="flex" justify="start" :gutter="24">
                      <a-col :xs="24" :sm="24" :md="12">
                        <SelectPagination
                          v-model="form.type"
                          :vid="labels.form.type.label"
                          :label="labels.form.type.label"
                          :placeholder="labels.form.type.placeholder"
                          :fetchService="fetchTransactionTypes"
                          :labelKey="'name'"
                          :valueKey="'id'"
                          :allowClear="true"
                          :disabledDefaultFetchOptions="false"
                          rules="required"
                          :disabled="isView"
                        />
                      </a-col>
                      <a-col v-if="isPayment" :xs="24" :sm="24" :md="12">
                        <a-row type="flex" justify="start" :gutter="3">
                          <a-col :xs="isView ? 24 : 20" :md="isView ? 24 : 22">
                            <Input
                              v-model="form.ticket.code"
                              :label="labels.form.ticket.label"
                              :placeholder="labels.form.ticket.placeholder"
                              :readOnly="true"
                              rules="required"
                              :allowClear="true"
                            />
                          </a-col>
                          <a-col v-if="!isView" :xs="4" :md="2">
                            <a-button
                              type="primary"
                              :style="{ marginTop: '44px' }"
                              @click="showTicketModal"
                              icon="select"
                              ghost
                            />
                          </a-col>
                        </a-row>
                      </a-col>
                    </a-row>
                    <a-row type="flex" justify="start" :gutter="24">
                      <a-col :xs="24" :sm="24" :md="12">
                        <SelectPagination
                          v-model="form.origin"
                          :vid="labels.form.origin.label"
                          :label="labels.form.origin.label"
                          :placeholder="labels.form.origin.placeholder"
                          :fetchService="fetchTransactionOrigins"
                          :labelKey="'name'"
                          :valueKey="'id'"
                          :allowClear="true"
                          :disabledDefaultFetchOptions="false"
                          rules="required"
                          :disabled="isView"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          v-model="form.amount"
                          type="number"
                          :label="labels.form.amount.label"
                          :placeholder="labels.form.amount.placeholder"
                          :allowClear="true"
                          :readOnly="
                            isTransfer ||
                            isPayment ||
                            (isView && !(isDeposit && isPending))
                          "
                          :disabled="
                            isTransfer ||
                            isPayment ||
                            (isView && !(isDeposit && isPending))
                          "
                          :precision="0"
                          :step="1"
                          :min="0"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <SelectPagination
                          v-model="form.user"
                          :vid="labels.form.user.label"
                          :label="labels.form.user.label"
                          :placeholder="labels.form.user.placeholder"
                          :fetchService="fetchUsers"
                          :labelKey="'fullname'"
                          :valueKey="'id'"
                          :allowClear="true"
                          :disabledDefaultFetchOptions="false"
                          rules="required"
                          :disabled="isView"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <SelectPagination
                          v-if="isTransfer"
                          v-model="form.destination_user"
                          :vid="labels.form.destination_user.label"
                          :label="labels.form.destination_user.label"
                          :placeholder="
                            labels.form.destination_user.placeholder
                          "
                          :fetchService="fetchUsers"
                          :labelKey="'fullname'"
                          :valueKey="'id'"
                          :allowClear="true"
                          :disabledDefaultFetchOptions="false"
                          rules="required"
                          :disabled="isView"
                        />
                        <Input
                          v-else
                          v-model="form.reference"
                          :label="labels.form.reference.label"
                          :placeholder="labels.form.reference.placeholder"
                          :readOnly="!isPending && isView"
                        />
                      </a-col>
                      <a-col :span="24" v-if="isView">
                        <Input
                          :value="form.user.email"
                          :label="labels.form.email.label"
                          :placeholder="labels.form.email.placeholder"
                          :allowClear="true"
                          :readOnly="true"
                        />
                      </a-col>
                    </a-row>
                    <a-row
                      v-if="
                        isView &&
                        !isTransfer &&
                        form.type?.name === TYPES.WITHDRAW
                      "
                      type="flex"
                      justify="start"
                      :gutter="24"
                    >
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          :value="form.bank"
                          :label="labels.form.bank.label"
                          :placeholder="labels.form.bank.placeholder"
                          :readOnly="isView"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          :value="form.account"
                          :label="labels.form.account.label"
                          :placeholder="labels.form.account.placeholder"
                          :readOnly="isView"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          :value="form.identification_card"
                          :label="labels.form.identification_card.label"
                          :placeholder="
                            labels.form.identification_card.placeholder
                          "
                          v-mask="'###-#######-#'"
                          :readOnly="isView"
                        />
                      </a-col>
                      <a-col :xs="24" :sm="24" :md="12">
                        <Input
                          :value="labels.form.account_type[form.account_type]"
                          :label="labels.form.account_type.label"
                          :placeholder="labels.form.account_type.placeholder"
                          :readOnly="isView"
                        />
                      </a-col>
                    </a-row>
                    <template
                      v-if="
                        [TYPES.WITHDRAW].includes(form.type?.name) && isCreating
                      "
                    >
                      <a-row type="flex" :gutter="[8, 8]">
                        <a-col :md="12" :sm="24" :xs="24">
                          <SelectPagination
                            v-model="form.bank"
                            :data="banksDropdown"
                            :label="labels.form.bank.label"
                            rules="required"
                            :placeholder="labels.form.bank.placeholder"
                            sortOptionsBy="label"
                            labelKey="label"
                            valueKey="id"
                            dropdownClassName="custom-select"
                          />
                        </a-col>
                        <a-col :md="12" :sm="24" :xs="24">
                          <Input
                            v-model="form.account"
                            rules="required"
                            vid="account"
                            :placeholder="labels.form.account.placeholder"
                            :allowClear="true"
                            :label="labels.form.account.label"
                          />
                        </a-col>
                      </a-row>
                      <a-row type="flex" :gutter="[8, 8]">
                        <a-col :md="12" :sm="24" :xs="24">
                          <Input
                            v-model="form.identification_card"
                            vid="identification_card"
                            v-mask="'###-#######-#'"
                            :placeholder="
                              labels.form.identification_card.placeholder
                            "
                            :allowClear="true"
                            :label="labels.form.identification_card.label"
                          />
                        </a-col>
                        <a-col :md="12" :sm="24" :xs="24">
                          <SelectPagination
                            v-model="form.account_type"
                            :data="accountTypes"
                            :label="labels.form.account_type.label"
                            rules="required"
                            :placeholder="labels.form.account_type.placeholder"
                            sortOptionsBy="label"
                            labelKey="label"
                            valueKey="value"
                            dropdownClassName="custom-select"
                          />
                        </a-col>
                      </a-row>
                    </template>
                    <a-row type="flex" justify="start" :gutter="24">
                      <a-col :span="24">
                        <TextArea
                          v-model="form.comment"
                          :label="labels.form.comment.label"
                          :placeholder="labels.form.comment.placeholder"
                          :readOnly="(!isPending && isView) || isTransfer"
                          :autoSize="{ minRows: 5, maxRows: 10 }"
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                  <a-col :sm="24" :md="12">
                    <template
                      v-if="
                        isCreating &&
                        [TYPES.WITHDRAW, TYPES.DEPOSIT].includes(
                          form.type?.name
                        )
                      "
                    >
                      <SelectPagination
                        v-model="form.origin_bank"
                        :data="banksAccountsDropdown"
                        :label="labels.form.origin_bank.label"
                        rules="required"
                        :placeholder="labels.form.origin_bank.placeholder"
                        sortOptionsBy="label"
                        labelKey="label"
                        valueKey="value"
                        dropdownClassName="custom-select"
                      />

                      <a-form-item
                        v-if="[TYPES.WITHDRAW].includes(form.type?.name)"
                        :style="{ width: '100%' }"
                        :label="labels.form.origin_voucher.label"
                      >
                        <a-upload
                          :disabled="submitting"
                          :multiple="true"
                          :accept="'image/*'"
                          :file-list="fileList"
                          list-type="picture"
                          :style="{ width: '100%' }"
                          :beforeUpload="beforeUpload"
                          :openFileDialogOnClick="true"
                          :remove="handleRemoveFile"
                          @preview="handlePreviewFile"
                          :customRequest="dummyRequest"
                        >
                          <a-button
                            size="large"
                            type="primary"
                            ghost
                            class="btn-upload"
                            :disabled="submitting"
                            :block="true"
                            :style="{ width: '100%' }"
                          >
                            <a-icon type="upload" />
                            {{ labels.form.origin_voucher.placeholder }}
                          </a-button>
                        </a-upload>
                      </a-form-item>

                      <a-form-item
                        v-if="[TYPES.DEPOSIT].includes(form.type?.name)"
                        :style="{ width: '100%' }"
                        :label="labels.form.voucher.label"
                      >
                        <a-upload
                          :disabled="submitting"
                          :multiple="true"
                          :accept="'image/*'"
                          :file-list="voucherFileList"
                          list-type="picture"
                          :style="{ width: '100%' }"
                          :beforeUpload="beforeUploadVoucher"
                          :openFileDialogOnClick="true"
                          :remove="handleRemoveVoucherFile"
                          @preview="handlePreviewFile"
                          :customRequest="dummyRequest"
                        >
                          <a-button
                            size="large"
                            type="primary"
                            ghost
                            class="btn-upload"
                            :disabled="submitting"
                            :block="true"
                            :style="{ width: '100%' }"
                          >
                            <a-icon type="upload" />
                            {{ labels.form.voucher.placeholder }}
                          </a-button>
                        </a-upload>
                      </a-form-item>
                    </template>

                    <Input
                      v-if="
                        [TYPES.WITHDRAW, TYPES.DEPOSIT].includes(
                          form.type?.name
                        ) &&
                        transaction?.status !== 'PENDING' &&
                        transaction?.origin_bank?.trim()
                      "
                      :value="form.origin_bank"
                      :label="labels.form.originBank.label"
                      :placeholder="labels.form.originBank.placeholder"
                      :readOnly="true"
                    />

                    <a-card
                      v-if="!isCreating && form?.voucher?.length"
                      size="small"
                      class="card-vouchers"
                      :title="labels.form.vouchersTitle"
                    >
                      <div class="voucher-container">
                        <template v-for="(v, idx) in form.voucher">
                          <span
                            v-if="v"
                            :key="idx"
                            class="voucher-image-container"
                          >
                            <a-icon
                              type="eye"
                              class="voucher-image-container-icon noprint"
                              @click="() => showImage(getFile(v))"
                            />
                            <img
                              :src="getFile(v)"
                              :alt="v"
                              class="voucher-image"
                              :onError="
                                (error) => {
                                  error.target.src = require('@/assets/images/no-image.png');
                                }
                              "
                            />
                          </span>
                        </template>
                      </div>
                    </a-card>

                    <Input
                      v-if="form?.updated_by?.name"
                      :value="form?.updated_by?.name"
                      :label="labels.form.updated_by.label"
                      :placeholder="labels.form.updated_by.placeholder"
                      :readOnly="true"
                      :disabled="true"
                    />

                    <date-picker
                      v-if="form?.updated_at"
                      :disabled="true"
                      :value="form.updated_at"
                      :formitem="{
                        label: labels.form.updated_at.label,
                        placeholder: labels.form.updated_at.placeholder,
                      }"
                      :readOnly="true"
                      format="DD/MM/YYYY hh:mm:ss A"
                    />

                    <a-card
                      v-if="
                        !isCreating &&
                        form?.origin_voucher?.length &&
                        transaction?.status !== 'PENDING'
                      "
                      size="small"
                      class="card-vouchers"
                      :title="labels.form.originVouchersTitle"
                    >
                      <div class="voucher-container">
                        <template v-for="(v, idx) in form.origin_voucher">
                          <span
                            v-if="v"
                            :key="idx"
                            class="voucher-image-container"
                          >
                            <a-icon
                              type="eye"
                              class="voucher-image-container-icon noprint"
                              @click="() => showImage(getFile(v))"
                            />
                            <img
                              :src="getFile(v)"
                              :alt="v"
                              class="voucher-image"
                              :onError="
                                (error) => {
                                  error.target.src = require('@/assets/images/no-image.png');
                                }
                              "
                            />
                          </span>
                        </template>
                      </div>
                    </a-card>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
    <table-modal
      :show="showModal"
      :title="labels.form.ticket.modal.title"
      @onSelect="handleSelectTicket"
      @onCancel="hideTicketModal"
      :fetchService="fetchWonOrders"
      :columns="columns"
      :width="screenWidth >= 1024 ? '1024px' : '90%'"
      :selection="true"
      :rowSelection="{
        type: 'radio',
      }"
      :defaultSelected="[form.ticket]"
    />
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "@/components/core/VeeValidateForm/Input.vue";
import DatePicker from "@/components/core/VeeValidateForm/DatePicker.vue";
import TextArea from "@/components/core/VeeValidateForm/TextArea.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import TableModal from "@/components/core/TableModal/Index.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import moment from "moment";
import accounting from "accounting";
import router from "@/router";
import { path } from "@/api";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";

export const ACCOUNT_TYPE = {
  SAVINGS: "SAVINGS",
  CHECKING: "CHECKING",
};

export const TYPES = {
  DEPOSIT: "Depósito",
  WITHDRAW: "Retiro",
};

export const ORIGINS = {
  TRANSFER: "Transferencia",
};

const initialFormState = {
  date: moment(),
  type: [],
  origin: [],
  user: [],
  ticket: {
    code: "",
  },
  reference: "",
  amount: null,
  comment: "",
  voucher: [],
  origin_bank: [],
  origin_voucher: [],
  bank: [],
  account: "",
  account_type: ACCOUNT_TYPE.SAVINGS,
};

export default {
  name: "TransactionForm",
  components: {
    ValidationObserver,
    Input,
    DatePicker,
    SelectPagination,
    TextArea,
    TableModal,
  },
  metaInfo: {
    title: adminPageTitle(labels.transactions.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("view")
          ? labels.transactions.pageTitleViewing
          : labels.transactions.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      labels: {
        ...labels.transactions,
        voucherLoadError: labels.transactionView.voucherLoadError,
      },
      accountTypes: Object.values(ACCOUNT_TYPE).map((accountType) => ({
        value: accountType,
        label: labels.transactionForm.accountType[accountType],
      })),
      TYPES,
      ORIGINS,
      ordersLabels: labels.orders,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
      showModal: false,
      errors: [],
      fileList: [],
      voucherFileList: [],
    };
  },
  async created() {
    this.resetForm();
    this.fetchBanksAccounts();
    this.fetchAdminBanks();
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  computed: {
    ...mapState("transactions", ["transaction"]),
    ...mapState("layout", ["screenWidth"]),
    ...mapState("systemConfig", ["banksAccounts", "banks"]),
    ...mapGetters({
      loggedUser: "adminAuth/getLoggedUser",
    }),
    banksAccountsDropdown() {
      return _.uniq(this.banksAccounts).map((bank) => ({
        label: bank,
        value: bank,
      }));
    },
    banksDropdown() {
      return _.uniq(this.banks).map((bank) => ({
        label: bank,
        value: bank,
      }));
    },
    isView() {
      return !!this.$route.params?.id;
    },
    isCreating() {
      return !this.$route.params?.id;
    },
    isPayment() {
      return this.form?.type?.name === "Pago";
    },
    isPending() {
      return this.transaction?.status === "PENDING";
    },
    isWithdraw() {
      return this.form?.type?.name === this.TYPES?.WITHDRAW;
    },
    dateFormat() {
      return DATE_FORMAT.MOMENT_DATE;
    },
    serverDateFormat() {
      return DATE_FORMAT.SERVER_DATE;
    },
    isAuthorizedToCancel() {
      const { accessRoles } = this.loggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_TRANSACTIONS_CANCEL]);
    },
    canReject() {
      const { accessRoles } = this.loggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_TRANSACTIONS_REJECT]);
    },
    canApprove() {
      const { accessRoles } = this.loggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_TRANSACTIONS_APPROVE]);
    },
    canConfirm() {
      const { accessRoles } = this.loggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_TRANSACTIONS_CONFIRM]);
    },
    columns() {
      return [
        {
          title: this.ordersLabels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
        {
          title: this.ordersLabels.table.columns.codeNumber,
          dataIndex: "code",
          key: "code",
          sorter: true,
        },
        {
          title: this.ordersLabels.table.columns.user,
          dataIndex: "user",
          key: "user",
          sorter: true,
          customRender: (text) => {
            return `${text?.firstname || ""} ${text?.lastname || ""}`.trim();
          },
        },
        {
          title: this.ordersLabels.table.columns.totalAmount,
          dataIndex: "total_amount",
          key: "total_amount",
          sorter: true,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.ordersLabels.table.columns.wonTotalAmount,
          dataIndex: "won_total_amount",
          key: "won_total_amount",
          sorter: true,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
      ];
    },
    isDeposit() {
      return this.form?.type?.name === this.TYPES?.DEPOSIT;
    },
    isTransfer() {
      return (
        this.form?.origin?.name === this.ORIGINS.TRANSFER &&
        this.form?.destination_user?.id
      );
    },
  },
  methods: {
    ...mapActions("transactions", [
      "fetchTransaction",
      "fetchTransactionTypes",
      "fetchTransactionOrigins",
      "processTransaction",
      "cancelTransaction",
      "approveTransaction",
      "rejectTransaction",
      "resetForm",
    ]),
    ...mapActions("users", ["fetchUsers"]),
    ...mapActions("orders", ["fetchWonOrders"]),
    ...mapActions("systemConfig", ["fetchBanksAccounts", "fetchAdminBanks"]),
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    },
    dummyRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    async beforeUpload(file) {
      const fileBase64 = await this.toBase64(file);
      this.fileList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        url: fileBase64,
        thumbUrl: fileBase64,
      });
      this.form.origin_voucher.push(file);
      return true;
    },
    async beforeUploadVoucher(file) {
      const fileBase64 = await this.toBase64(file);
      this.voucherFileList.push({
        uid: file.uid,
        name: file.name,
        status: "done",
        url: fileBase64,
        thumbUrl: fileBase64,
      });
      this.form.voucher.push(file);
      return true;
    },
    handlePreviewFile(file) {
      // CREATE IMG AND OPEN IN NEW TAB
      const img = document.createElement("img");
      img.src = file.url || file.thumbUrl;
      img.style.maxWidth = "520px";
      const w = window.open("", "_blank", "width=520,height=auto");
      w?.document.write(img.outerHTML);
    },
    handleRemoveFile(file) {
      if (file?.uid) {
        this.form.origin_voucher = this.form.origin_voucher?.filter(
          (item) => item.uid !== file.uid
        );
        this.fileList = this.fileList?.filter((item) => item.uid !== file.uid);
      }
    },
    handleRemoveVoucherFile(file) {
      if (file?.uid) {
        this.form.voucher = this.form.voucher?.filter(
          (item) => item.uid !== file.uid
        );
        this.voucherFileList = this.voucherFileList?.filter(
          (item) => item.uid !== file.uid
        );
      }
    },
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchTransaction(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
        this.$router.push({ name: "admin.transactions" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false, confirmed = false) {
      if (this.submitting) return;
      try {
        // VALIDATE VOUCHER WHEN DEPOSIT
        if (
          this.isDeposit &&
          !this.form.voucher.length &&
          !this.form.origin_bank?.value?.toLowerCase()?.includes("bono")
        ) {
          this.$error({
            class: "error-modal",
            title: this.labels.form.formError,
            content: this.labels.form.voucher.required,
          });
          return;
        }

        this.submitting = true;
        let response = null;
        let editting = this.isView;

        const data = _.cloneDeep(this.form);
        const formData = new FormData();

        if (_.isEqual(data.ticket, initialFormState.ticket)) {
          data.ticket = null;
        }
        data.date = this.$moment(data.date).format(this.serverDateFormat);

        Object.keys(data)
          .filter((key) => !!data[key] && key !== "ticket")
          .forEach((key) => {
            if (["origin_voucher", "voucher"].includes(key)) {
              data[key].forEach((file) => {
                formData.append(`${key}[]`, file, file.name);
              });
            } else {
              if (["origin_bank", "bank"].includes(key)) {
                formData.append(key, data[key].value);
              } else if (["type", "origin", "user"].includes(key)) {
                formData.append(`${key}[id]`, data[key].id);
              } else {
                formData.append(key, data[key]);
              }
            }
          });

        if (confirmed) {
          formData.append("confirmed", confirmed);
        }

        response = await this.processTransaction(formData);

        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.transactions" });
        } else {
          if (!editting) {
            this.$router.push({
              name: "admin.transactions.view",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
      } finally {
        this.submitting = false;
      }
    },
    statusColor(status = "success") {
      switch (status) {
        case "success":
        case "paid":
          return "#28a745";
        case "pending":
          return "#ffc107";
        case "cancelled":
        case "rejected":
          return "#f50";
        case "processed":
          return "#108ee9";
        default:
          return "";
      }
    },
    statusText(status = this.labels.table.columns.status.success) {
      return this.labels.table.columns?.status[status] ?? " - ";
    },
    handleCancel() {
      this.$router.push({ name: "admin.transactions" });
    },
    async onlyAlfa(value = "", field) {
      this.form[field] = await value.replace(/[^a-zA-Z\s]/g, "");
    },
    showTicketModal() {
      this.showModal = true;
    },
    hideTicketModal() {
      this.showModal = false;
    },
    handleSelectTicket(ticket) {
      Object.assign(this.form, {
        ticket: ticket || _.cloneDeep(initialFormState.ticket),
        amount: ticket?.won_total_amount || 0,
        user: ticket?.user
          ? {
              ...ticket.user,
              fullname: `${ticket.user.firstname} ${ticket.user.lastname}`,
            }
          : [],
      });
      this.showModal = false;
    },
    confirmCancel() {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.cancelTransaction(this.form.id);
            this.$message.success(this.labels.cancelledSuccess);
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.cancelledError
            );
          }
        },
      });
    },
    confirmReject() {
      this.$confirm({
        title: this.labels.confirmReject.title,
        content: (h) =>
          h(
            "div",
            {
              class: "confirm-reject-content",
            },
            [
              h("p", this.labels.confirmReject.subtitle),
              h(
                "a-form-item",
                {
                  props: {
                    label: this.labels.form.rejectReason.label,
                    labelCol: { span: 24 },
                  },
                },
                [
                  h("a-textarea", {
                    props: {
                      placeholder: this.labels.form.rejectReason.placeholder,
                      value: this.form.reject_reason,
                      allowClear: true,
                      autoSize: { minRows: 3, maxRows: 5 },
                    },
                    on: {
                      input: (event) => {
                        this.form.reject_reason = event.target.value;
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
        okText: this.labels.confirmReject.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirmReject.cancelButtonText,
        onOk: async () => {
          try {
            await this.rejectTransaction({
              id: this.form.id,
              ...(this.form.reject_reason && {
                reject_reason: this.form.reject_reason,
              }),
            });
            this.$message.success(this.labels.rejectedSuccess);
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.rejectedError
            );
          }
        },
      });
    },
    async handleApproveTransaction(confirmed = false) {
      if (this.submitting) return;
      try {
        this.submitting = true;
        if (
          [this.TYPES.WITHDRAW, this.TYPES.DEPOSIT].includes(
            this.form.type?.name
          ) &&
          !this.isTransfer
        ) {
          if (!this.form.origin_bank) {
            return new Promise((_, reject) => {
              this.errors = [this.labels.form.bank.required];
              reject();
            });
          }
        }

        const formData = new FormData();
        formData.append("_method", "put");
        const data = {
          ...(this.form.origin_bank && {
            origin_bank: this.form.origin_bank,
          }),
          ...(this.form.origin_voucher && {
            origin_voucher: this.form.origin_voucher,
          }),
          ...(this.form.amount && {
            amount: this.form.amount,
          }),
          ...(this.form.reference && { reference: this.form.reference }),
          ...(confirmed && {
            confirmed,
          }),
          ...(this.form.comment && {
            comment: this.form.comment,
          }),
        };
        Object.keys(data)
          .filter((key) => !!this.form[key] || key === "confirmed")
          .forEach((key) => {
            if (key === "origin_voucher") {
              this.form[key].forEach((file) => {
                formData.append("origin_voucher[]", file, file.name);
              });
            } else {
              formData.append(key, data[key]);
            }
          });
        await this.approveTransaction({
          id: this.form.id,
          formData,
        });
        this.$message.success(this.labels.approvedSuccess);
      } catch (error) {
        console.error(error);
        if (error?.response?.status === 409) {
          this.confirmWarning(
            error?.response?.data?.message ||
              error?.response?.message ||
              error.message
          );
        } else {
          this.$message.error(
            error?.response?.data?.message ||
              error?.response?.message ||
              error.message ||
              this.labels.approvedError
          );
        }
      } finally {
        this.submitting = false;
      }
    },
    confirmWarning(message) {
      this.$confirm({
        title: this.labels.confirmWarning.title,
        content: (h) =>
          h("p", {
            domProps: {
              innerHTML: message || this.labels.confirmWarning.subtitle,
            },
          }),
        okText: this.labels.confirmWarning.confirmButtonText,
        cancelText: this.labels.confirmWarning.cancelButtonText,
        okType: "danger",
        cancelButtonProps: {
          type: "primary",
          ghost: true,
        },
        icon: "warning",
        closable: false,
        keyboard: false,
        maskClosable: false,
        onOk: () => {
          this.handleApproveTransaction(true);
        },
      });
    },
    confirmAndContinueWithDeposit() {
      try {
        this.$confirm({
          title: this.labels.confirmPreview.title,
          content: (h) =>
            h(
              "div",
              {
                class: "confirm-preview",
              },
              [
                h("p", this.labels.confirmPreview.subtitle),
                h(
                  "div",
                  {
                    class: "confirm-preview-content",
                  },
                  [
                    h(
                      "div",
                      {
                        class: "confirm-preview-content-item",
                      },
                      [
                        h("label", {
                          class: "confirm-preview-content-item-label",
                          domProps: {
                            innerHTML: this.labels.confirmPreview.customer,
                          },
                        }),
                        h("span", {
                          class: "confirm-preview-content-item-value",
                          domProps: {
                            innerHTML: this.form.user.fullname,
                          },
                        }),
                      ]
                    ),
                    h(
                      "div",
                      {
                        class: "confirm-preview-content-item",
                      },
                      [
                        h("label", {
                          class: "confirm-preview-content-item-label",
                          domProps: {
                            innerHTML: this.labels.confirmPreview.email,
                          },
                        }),
                        h("span", {
                          class: "confirm-preview-content-item-value",
                          domProps: {
                            innerHTML: this.form.user.email,
                          },
                        }),
                      ]
                    ),
                    h(
                      "div",
                      {
                        class: "confirm-preview-content-item",
                      },
                      [
                        h("label", {
                          class: "confirm-preview-content-item-label",
                          domProps: {
                            innerHTML: this.labels.confirmPreview.amount,
                          },
                        }),
                        h("span", {
                          class: "confirm-preview-content-item-value money",
                          domProps: {
                            innerHTML: accounting.formatMoney(
                              this.form.amount,
                              "RD$ ",
                              2,
                              ",",
                              "."
                            ),
                          },
                        }),
                      ]
                    ),
                    h(
                      "div",
                      {
                        class: "confirm-preview-content-item",
                      },
                      [
                        h("label", {
                          class: "confirm-preview-content-item-label",
                          domProps: {
                            innerHTML: this.labels.confirmPreview.bank,
                          },
                        }),
                        h("span", {
                          class: "confirm-preview-content-item-value",
                          domProps: {
                            innerHTML: this.form.origin_bank,
                          },
                        }),
                      ]
                    ),
                    h(
                      "div",
                      {
                        class: "confirm-preview-content-item",
                      },
                      [
                        h("label", {
                          class: "confirm-preview-content-item-label",
                          domProps: {
                            innerHTML: this.labels.confirmPreview.reference,
                          },
                        }),
                        h("span", {
                          class: "confirm-preview-content-item-value",
                          domProps: {
                            innerHTML: this.form.reference,
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          okText: this.labels.confirmPreview.confirmButtonText,
          okType: "danger",
          icon: "info-circle",
          cancelText: this.labels.confirmPreview.cancelButtonText,
          visible: true,
          okButtonProps: {
            disabled: this.submitting,
            loading: this.submitting,
          },
          onOk: async () => {
            this.errors = [];
            await this.handleApproveTransaction();
          },
          onCancel: () => {
            this.confirmApprove();
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    confirmApprove() {
      try {
        this.$confirm({
          title: this.labels.confirmApprove.title,
          ...(![this.TYPES.WITHDRAW, this.TYPES.DEPOSIT].includes(
            this.form.type?.name
          ) && {
            content: this.labels.confirmApprove.subtitle,
          }),
          ...([this.TYPES.WITHDRAW, this.TYPES.DEPOSIT].includes(
            this.form.type?.name
          ) && {
            content: (h) =>
              h(
                "div",
                {
                  class: "confirm-approve-content",
                },
                [
                  h("p", this.labels.confirmApprove.subtitle),
                  h(
                    "a-form-item",
                    {
                      props: {
                        label: this.labels.form.bank.label,
                        labelCol: { span: 24 },
                        ...(this.errors.length && {
                          validateStatus: "error",
                          help: this.errors[0],
                        }),
                      },
                    },
                    [
                      h("a-select", {
                        props: {
                          placeholder: this.labels.form.bank.placeholder,
                          modelValue: this.form.origin_bank,
                          allowClear: true,
                          options: this.banksAccountsDropdown,
                          ...(this.form.origin_bank && {
                            defaultValue: this.form.origin_bank,
                          }),
                        },
                        on: {
                          change: (event) => {
                            if (this.errors.length) this.errors = [];
                            this.form.origin_bank = event;
                          },
                        },
                        style: {
                          width: "100%",
                        },
                      }),
                    ]
                  ),
                  ...(this.isWithdraw
                    ? [
                        h(
                          "a-form-item",
                          {
                            props: {
                              label: this.labels.form.originVoucher.label,
                              labelCol: { span: 24 },
                            },
                          },
                          [
                            h(
                              "a-upload",
                              {
                                props: {
                                  disabled: this.submitting,
                                  multiple: true,
                                  accept: "image/*",
                                  fileList: this.fileList,
                                  listType: "picture",
                                  beforeUpload: this.beforeUpload,
                                  openFileDialogOnClick: true,
                                  remove: this.handleRemoveFile,
                                  customRequest: this.dummyRequest,
                                },
                                on: {
                                  preview: this.handlePreviewFile,
                                },
                                style: { width: "100%" },
                              },
                              [
                                h(
                                  "a-button",
                                  {
                                    props: {
                                      type: "primary",
                                      disabled: this.submitting,
                                      icon: "upload",
                                      ghost: true,
                                      class: "btn-upload",
                                      block: true,
                                    },
                                    style: { width: "100%" },
                                  },
                                  this.labels.form.uploadOriginVoucher
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : []),
                ]
              ),
          }),
          okText: this.labels.confirmApprove.confirmButtonText,
          okType: "danger",
          icon: "question-circle",
          cancelText: this.labels.confirmApprove.cancelButtonText,
          visible: true,
          okButtonProps: {
            disabled: this.submitting,
            loading: this.submitting,
          },
          onOk: async () => {
            this.errors = [];
            if (this.isDeposit) {
              this.confirmAndContinueWithDeposit();
            } else {
              await this.handleApproveTransaction();
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    getFile(file) {
      return path.client.files.single(file);
    },
    async showImage(voucher) {
      const image = new Image();
      image.src = voucher;
      image.style.maxWidth = "520px";
      image.id = "voucher-image";
      const w = window.open("", "_blank", "width=520,height=auto");
      w?.document.write(image.outerHTML);
      w?.document
        .getElementById("voucher-image")
        ?.addEventListener("error", () => {
          this.$message.error(this.labels.voucherLoadError);
          w?.close();
        });
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    transaction: {
      handler(transaction) {
        Object.assign(
          this.form,
          transaction
            ? _.cloneDeep({
                ...transaction,
                user: transaction.user
                  ? {
                      ...transaction.user,
                      fullname: `${transaction.user.firstname} ${transaction.user.lastname}`,
                    }
                  : null,
                destination_user: transaction.destination_user
                  ? {
                      ...transaction.destination_user,
                      fullname: `${transaction.destination_user.firstname} ${transaction.destination_user.lastname}`,
                    }
                  : null,
                ticket:
                  transaction.ticket || _.cloneDeep(initialFormState.ticket),
              })
            : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.transactions-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
  .ant-input-number-disabled,
  .ant-input[disabled],
  .ant-select-disabled .ant-select-selection {
    background-color: color(--white);
    color: rgba(0, 0, 0, 0.65);
  }
  .card-vouchers {
    border-style: dashed;
    padding: 0;
    border-radius: 10px;
    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }
    .ant-card-body {
      padding: 0;
    }
    .voucher-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      align-items: center;
      gap: 15px;
      min-height: 240px;
      .voucher-image {
        &-container {
          position: relative;
          width: 240px;
          max-width: 240px;
          height: 240px;
          object-fit: cover;
          padding: 15px;
          &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-40%, -50%);
            font-size: 2rem;
            color: color(c-hover);
            opacity: 0.8;
            transition: all 0.5s ease;
            background-color: color(c-primary);
            padding: 2px 4px;
            border-radius: 15%;
            &:hover {
              opacity: 1;
              transition: all 0.5s ease;
              cursor: pointer;
            }
          }
        }
        width: 240px;
        max-width: 240px;
        max-height: 100%;
        object-fit: cover;
        border: 5px solid color(--white);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.confirm-reject-content {
  .ant-form-item-label label {
    font-weight: 600 !important;
  }
}
.anticon-delete {
  color: color(danger) !important;
  font-size: 20px;
  margin-right: 10px;
}
.ant-upload {
  width: 100%;
}
.confirm-preview {
  display: flex;
  width: 100%;
  flex-direction: column;
  .confirm-preview-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .confirm-preview-content-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .confirm-preview-content-item-label {
        font-weight: 600;
        &:after {
          content: ":";
        }
      }
      .confirm-preview-content-item-value {
        font-weight: 400;
        &.money {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
